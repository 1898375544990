import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "server-side-rendering"
    }}>{`Server-Side Rendering`}</h1>
    <p>{`If you're using SSR, you can pass in data fetched on the server to initialize the client by using the `}<inlineCode parentName="p">{`ssrData`}</inlineCode>{` option.`}</p>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const { data, error, loading } = useAxios({
  ssrData: [
    {
      id: '55b797ca21414b0cbad9847ebe6f3d64',
      name: 'Avril Lavigne',
    },
  ],
  url: '/rockstars/2',
});
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      